import React from "react";

export const EditButton = ({ value = "Edit", onTap = () => {} }) => {
  return (
    <button
      type="button"
      className="btn 
                btn-dark 
                rounded-pill 
                border-0 
                edit-button 
                d-flex 
                align-items-center 
                px-3"
      onClick={onTap}
      style={{ backgroundColor: "#e5991e" }}
    >
      {value}
    </button>
  );
};


export const SubmitButton = ({ value = "Submit", onTap = () => {} }) => {
  return (
    <input
      type="submit"
      className="btn 
                btn-dark 
                bg-green 
                rounded-pill 
                border-0 
                edit-button 
                d-flex 
                align-items-center 
                px-3"
      onClick={onTap}
      value={value}
    />
  );
};

export const DeleteButton = ({ value = "Delete", onTap = () => {} }) => {
  return (
    <button
      type="button"
      className="btn 
                btn-dark 
                bg-orange
                rounded-pill 
                border-0 
                edit-button 
                d-flex 
                align-items-center 
                px-3"
      onClick={onTap}
    >
      {value}
    </button>
  );
};


export const ShowAllButton = ({ value, onTap = () => {} }) => {
  return (
    <button
      type="button"
      className="btn 
                btn-dark 
                bg-green 
                border-0 
                d-flex 
                align-items-center 
                px-3"
      onClick={onTap}
    >
      {value}
    </button>
  );
};
