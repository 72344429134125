import React from "react";
import i18next from "i18next";
import "./Lang.css";
import { IoLanguage } from "react-icons/io5";

const Language = () => {
  return (
    <div className="dropdown language-selector">
      <button
        className="btn btn-link dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <IoLanguage size={25}/>
      </button>
      <ul className="dropdown-menu mb-2" aria-labelledby="dropdownMenuButton1">
        <li>
          <button
            className="dropdown-item"
            onClick={() => i18next.changeLanguage("en")}
          >
            English
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            onClick={() => i18next.changeLanguage("hi")}
          >
            हिंदी
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Language;
