import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore"
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import {checkUserPayment} from "../auth/authUtils";

const SignIn = () => {
  const { t } = useTranslation();
  // useState for warning message
  const [warn, setWarn] = useState("");
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };
  const navigate = useNavigate();

  // Redirect to home if user is logged in
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        return navigate("/", { replace: true });
      }
    });
    return () => {
      listen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pan, setPan] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState("password");

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const docRef = doc(db, "usersTable", pan);
    const docSnap = await getDoc(docRef);
    const emailPAN = docSnap.data().emailPAN;

    signInWithEmailAndPassword(auth, emailPAN, password)
      .then((userCredential) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        newWarn(error.message);
      });
  
    try {
      const docRef = doc(db, "usersTable", pan);
      const docSnap = await getDoc(docRef);
      const emailPAN = docSnap.data().emailPAN;
  
      await signInWithEmailAndPassword(auth, emailPAN, password);
  
      // User is fully signed in at this point
      setLoading(false);
      console.log("User details:", auth.currentUser);
  
      // Now, check user payment
      checkUserPayment(auth.currentUser, navigate);
    } catch (error) {
      setLoading(false);
      newWarn(error.message);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <div className="signin-page mt-5 p-4">
      <form onSubmit={signIn}>
        <center>
          <img src={logo} className="app-logo-sm" alt="logo" />
          <h1 className="mt-2">{t("Log_In")}</h1>
        </center>
        <div className="mb-3">
          <label htmlFor="pan" className="form-label">
            {t("PAN number")}
          </label>
          <input
            name="pan"
            type="pan"
            value={pan}
            onChange={(e) => setPan(e.target.value)}
            className="form-control"
            id="exampleInputPAN1"
            aria-describedby="PANHelp"
            required
          />
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="form-label">
            {t("Password")}
          </label>
          <input
            name="password"
            type={type}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              verticalAlign: "middle",
              top: "37px",
              right: "9px",
              height: "22px",
            }}
            className="flex justify-around items-center"
            onClick={handleToggle}
          >
            <Icon class="absolute mr-10" icon={icon} size={25} />
          </span>
        </div>
        <button
          type="submit"
          className="btn btn-signup mt-3"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : t("Log_In")}
        </button>
        {/* <div id="emailHelp" className="form-text mt-3">
          {t("Dont_have_an_account")} <Link to="/auth/signup">{t("Register")}</Link>{" "}
        </div> */}
        <div id="emailHelp" className="form-text mt-3">
          <Link to="/auth/reset">{t("Forgot_Password")}</Link>{" "}
        </div>
        {warn && (
          <div className="alert alert-warning mt-1" role="alert">
            {warn}
          </div>
        )}
      </form>
    </div>
  );
};

export default SignIn;
