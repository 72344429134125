import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { Dashboard, Profile, Course, Services } from "./";

import { useUser } from "../data";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { UserCard } from "../components";
import ErrorBoundary from "../ErrorBoundary";

const Home = () => {
  // const { t } = useTranslation();
  const [authUser, setAuthUser] = useState({});
  const navigate = useNavigate();
  const [scaffold, setScaffold] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [userPAN, setUserPAN] = useState({});
  const [userName, setUserName] = useState();
  // Auth user
  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthUser(user);
        // check if all the answers are filled
        const match = user.email.match(/\+(.*?)@/);
        const pan = match[1].toUpperCase();
        setUserPAN(pan);
        setUserName(user.displayName);
        const docRef = doc(db, "users", pan);
        const docSnap = await getDoc(docRef);
        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const questRef = doc(db, currentYear.toString(), pan);
        const questSnap = await getDoc(questRef);
        if (questSnap.exists()) {
          if (questSnap.data()["paymentRequired"] === true) {
            return navigate("/error", { replace: true });
          }

          // if (questSnap.data()["question"] === 0) {
          //   return navigate("/questions/nbsp", { replace: true });
          // }
          // if (!questSnap.data()["question"].gross[0]) {
          //   return navigate(`/questions/nbsp/${currentYear}`, {
          //     replace: true,
          //   });
          // } else if (questSnap.data()["question"].gross[0] === "") {
          //   return navigate(`/questions/nbsp/${currentYear}`, {
          //     replace: true,
          //   });
          // }
        }
        setUserData(docSnap.data());
        setIsLoading(false);
      } else {
        setAuthUser(null);
        return navigate("/auth/signin", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  // useEffect(() => {
  //   const data = async () => {
  //     const data = await
  //     alert(data);
  //   };
  // });
  // const userHookData = useUser(authUser);
  const activeTab = (i) => {
    if (i === scaffold) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <>
      {isLoading && (
        <center>
          <div className="loader">
            Retrieving User Authentication. Please Wait
          </div>
        </center>
      )}
      {!isLoading && (
        <div className="main">
          <br />
          <div className="scaffold pt-1 bg-light">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <button
                  onClick={() => {
                    setScaffold(1);
                  }}
                  className={`nav-link ${activeTab(1)} text-green`}
                >
                  My Space
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    setScaffold(0);
                  }}
                  className={`nav-link ${activeTab(0)} text-green`}
                >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    setScaffold(2);
                  }}
                  className={`nav-link ${activeTab(2)} text-green`} 
                >
                  Courses
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    setScaffold(3);
                  }}
                  className={`nav-link ${activeTab(3)} text-green`}
                >
                  Services
                </button>
              </li>
            </ul>
          </div>
          <div className="scaffoldBody">
            <UserCard userName={userData?.name} userPAN={userPAN} />
            {scaffold === 0 && <Dashboard userPAN={userPAN} />}
            {scaffold === 1 && (
              <Profile name={userData?.name} pan={userPAN} phone={""} userType={""} />
            )}
            {scaffold === 2 && <Course />}
            {scaffold === 3 && <Services />}
          </div>

          {/* <h1>Home</h1> */}
          {/* // dynamic translation */}
          {/* <p>{t('Hello_User',{name:userName})}</p>
      <p>{authUser?JSON.stringify(authUser.uid): "NULL"}</p> */}
        </div>
      )}
    </>
  );
};

export default function HomeErrorBoundary() {
  return (
    <ErrorBoundary>
      <Home />
    </ErrorBoundary>
  );
}
