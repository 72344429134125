/* eslint-disable react-hooks/exhaustive-deps */
import { onAuthStateChanged } from "firebase/auth";
import { React, useState, useEffect, useCallback, useContext } from "react";
import { DeleteButton, EditButton, SubmitButton } from "../components/buttons";
import { Link } from "react-router-dom";
import { signOut, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../firebase";
import ErrorBoundary from "../ErrorBoundary";
import { useUser } from "../data";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { raiseQuery } from "../data/query";
import { collection, query, where, getDocs } from "firebase/firestore";
import toast from "react-hot-toast";


const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = auth.currentUser;
  const userData = useUser();
  console.log(userData);
  console.log(userData?.userdata?.aadharNo);
  const [edit, setEdit] = useState(false);
  const [editPrimary, setEditPrimary] = useState(false);
  const [editOtherEmp, setEditOtherEmp] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  // const [policeStation, setPoliceStation] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [Designation, setDesignation] = useState("");
  const [TAN, setTAN] = useState("");
  const [UserPan, setUserPan] = useState(props.pan);

  const [officeAddress, setOfficeAddress] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [roleSet, setRoleSet] = useState("");
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("https://tinyurl.com/4ypa9c6z");
  const [link, setLink] = useState("");
  const [year] = useState(process.env.REACT_APP_CURRENT_YEAR);
  const [isEditVisible, setIsEditVisible] = useState(true);


  const [employers, setEmployers] = useState([
    // Initial employer data
    { employerName: "", tan: "", empPAN: "" },
  ]);
  const [employerName, setEmployerName] = useState("");
  const [tan, setTan] = useState("");
  const [empPAN, setEmpPAN] = useState("");
  const [showInputFields, setShowInputFields] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);

  const [queryValue, setQueryValue] = useState("");
  const [previousQueries, setPreviousQueries] = useState([]);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAadhaar(userData?.userdata?.aadharNo);
        setIfscCode(userData?.userdata?.ifscCode);
        setEmployeeId(userData?.userdata?.employeeId)
        setAccountNumber(userData?.userdata?.bankAccountNo);
        // setPoliceStation(userData?.policeStation);
        setOfficeAddress(userData?.userdata?.officeAddress);
        setOfficeName(userData?.incomeSalary[0]?.employerName);
        setEmail(userData?.userdata?.email);
        setDesignation(userData?.incomeSalary[0]?.designation);
        setTAN(userData?.incomeSalary[0]?.tan);
        setMobile(userData?.userdata?.mobile);
        // setRoleSet(userData?.roleSet);
        if (authUser?.photoURL) {
          setPhotoUrl(authUser.photoURL);
        }
        const match = user.email.match(/\+(.*?)@/);
        const pan = match[1].toUpperCase();

        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const questRef = doc(db, currentYear.toString(), pan);
        const questSnap = await getDoc(questRef);
        if (questSnap.exists()) {
          if (questSnap.data()["path"] === -1) {
            setLink("");
          } else {
            setLink(questSnap.data()["path"]);
          }
        }

        // const employerRef = doc(db, currentYear.toString(), pan);
        // const employerSnap = await getDoc(employerRef);
        // setEmployers(employerSnap.data()?.Employers);
        setEmployers(userData?.incomeSalary);

        const fetchPreviousQueries = async () => {
          if (authUser && authUser.email) {
            const match = authUser.email.match(/\+(.*?)@/);
            const pan = match[1].toUpperCase();

            const queriesCollection = collection(db, "queries");
            const q = query(queriesCollection, where("pan", "==", pan));
            const querySnapshot = await getDocs(q);

            const queries = querySnapshot.docs.map((doc) => ({
              id: doc.id, // Add document ID
              query: doc.data().query,
              status: doc.data().status
            }));
            console.log("Fetched queries:", queries);
            setPreviousQueries(queries);
          }
        };

        fetchPreviousQueries(); // Add this line
      }
    });
    return () => {
      listen();
    };
  }, [userData, authUser]);

  const handleAadharChange = (value) => {
    const inputValue = value;
    const formattedAadhar = formatAadharNumber(inputValue);
    setAadhaar(formattedAadhar);
  };

  const accountInfo = [
    {
      label: t("Bank Account Number"),
      value: accountNumber,
      name: "accountNumber",
      setValue: setAccountNumber,
    },
    {
      label: t("Re-enter Bank Account Number"),
      value: accountNumber,
      name: "accountNumber",
      setValue: setAccountNumber,
    },
    {
      label: t("IFSC Code"),
      value: ifscCode,
      name: "ifscCode",
      setValue: setIfscCode,
    },
    {
      label: t("Aadhaar Number"),
      value: aadhaar,
      name: "aadhaar",
      setValue: handleAadharChange,
    },
    // {
    //   label: t("Police Station"),
    //   value: policeStation,
    //   name: "policeStation",
    //   setValue: setPoliceStation,
    // },
    {
      label: t("Employee ID"),
      value: employeeId,
      name: "employeeId",
      setValue: setEmployeeId,
    },
    // {
    //   label: t("Designation"),
    //   value: Designation,
    //   name: "Designation",
    //   setValue: setDesignation,
    // },
    // {
    //   label: t("TAN"),
    //   value: TAN,
    //   name: "TAN",
    //   setValue: setTAN,
    // },
    {
      label: t("PAN"),
      value: UserPan,
      name: "PAN",
      setValue: setUserPan,
    },
    // {
    //   label: t("Office Address"),
    //   value: officeAddress,
    //   name: "officeAddress",
    //   setValue: setOfficeAddress,
    // },
    {
      label: t("Email"),
      value: email,
      name: "email",
      setValue: setEmail,
    },
    {
      label: t("Mobile"),
      value: mobile,
      name: "mobile",
      setValue: setMobile,
    },
  ];

  const primaryInfo = [
    {
      label: t("Employee ID"),
      value: employeeId,
      name: "employeeId",
      setValue: setEmployeeId,
    },
    {
      label: t("Designation"),
      value: Designation,
      name: "Designation",
      setValue: setDesignation,
    },
    {
      label: t("TAN"),
      value: TAN,
      name: "TAN",
      setValue: setTAN,
    },
    {
      label: t("PAN"),
      value: UserPan,
      name: "PAN",
      setValue: setUserPan,
    },
    {
      label: t("Office Address"),
      value: officeAddress,
      name: "officeAddress",
      setValue: setOfficeAddress,
    },
    {
      label: t("Office Name"),
      value: officeName,
      name: "officeName",
      setValue: setOfficeName,
    },
  ];

  const formatAadharNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += " ";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue;
  };


  // Uploading data into the database
  const updateEmployeer = useCallback((employers) => {
    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    // const tanSource = doc(db, year, pan);
    // try {
    // console.log(employers);
    //   updateDoc(tanSource, { Employers: employers });
    // } catch (err) {
    //   console.log(err);
    // }

    const itrWorkingRef = doc(db, "itrWorking", currentYear);

    const employerData = {
      incomeFromSalaries: employers.map(emp => ({
        employerName: emp.employerName,
        tan: emp.tan,
        empPAN: emp.empPAN,
      })),
    };

    try {
      updateDoc(itrWorkingRef, employerData);
    } catch (err) {
      console.log(err);
    }

  });

  // updating the Employeer state
  const addEmployer = (e) => {
    e.preventDefault();
    if (employerName && tan && empPAN) {
      setEmployers([...employers, { employerName, tan, empPAN }]);
      updateEmployeer([...employers, { employerName, tan, empPAN }]);
      setEmployerName("");
      setTanNumber("");
      setPanNumber("");
      setShowInputFields(false); // hide input fields after submission
      setShowAddButton(true);
    }
  };

  // Function to handle Aadhar number input change

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setEdit(false);
    const formData = new FormData(e.target);
    // console.log(accountInfo.accountNumber);
    // console.log(formData.get("accountNumber"));
    const obj = {
      accountNumber: formData.get("accountNumber"),
      IFSC: formData.get("ifscCode"),
      aadhaar: formData.get("aadhaar"),
      // policeStation: formData.get("policeStation"),
      employeeId: formData.get("employeeId"),
      // Designation:formData.get("Designation"),
      // TAN:formData.get("TAN"),
      // officeAddress: formData.get("officeAddress"),
      email: formData.get("email"),
      mobile: formData.get("mobile"),
    };

    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, "users", pan);
    try {
      updateDoc(docRef, obj);
      // console.log("Document updated successfully!");
      toast.success(t("Details Updated Successfully!"));
      // navigate to home page
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error("Something went wrong, please try again");
      console.error("Error updating document: ", error);
    }
  }, []);


  const onPrimarySubmit = useCallback((e) => {
    e.preventDefault();
    setEditPrimary(false);
    const formData = new FormData(e.target);
    // console.log(accountInfo.accountNumber);
    // console.log(formData.get("accountNumber"));
    console.log("form data", formData);
    const obj = {
      employeeId: formData.get("employeeId"),
      Designation: formData.get("Designation"),
      TAN: formData.get("TAN"),
      officeAddress: formData.get("officeAddress"),
      officeName: formData.get("officeName"),
      // pan: formData.get("PAN"),
    };

    console.log("officeName :", officeName);
    console.log("officeAddress :", officeAddress);
    // console.log("pan", pan);
    console.log("employeeId :", employeeId);
    console.log("Designation :", Designation);
    // console.log("TAN", TAN);

    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, "users", pan);
    try {
      updateDoc(docRef, obj);
      // console.log("Document updated successfully!");
      toast.success(t("Details Updated Successfully!"));
      // navigate to home page
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error("Something went wrong, please try again");
      console.error("Error updating document: ", error);
    }
  }, []);

  const editAccountInfo = () => {
    setEdit(true);
  };

  const editPrimaryInfo = () => {
    setEditPrimary(true);
  };

  const editOtherEmpInfo = () => {
    setEditOtherEmp(true);
  };

  const submitAccountInfo = () => {
    setEdit(false);
  };

  const submitPrimaryInfo = () => {
    setEditPrimary(false);
  };

  const submitOtherEmpInfo = () => {
    setEditOtherEmp(false);
  };


  const onEmployerSubmit = (e) => {
    e.preventDefault();
    // Handle submission of edited other employers data
    console.log("Submit other employers data:", employers);

    // Update the database with the edited employer details
    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, year, pan);

    try {
      updateDoc(docRef, { Employers: employers });
      console.log("Employer details updated successfully!");
    } catch (error) {
      console.error("Error updating employer details:", error);
    }

    setEditOtherEmp(false); // Reset edit mode after submission
  };


  const onEmployerChange = (index, fieldName, value) => {
    const updatedEmployers = [...employers];
    updatedEmployers[index][fieldName] = value;
    setEmployers(updatedEmployers);
  };

  const deleteEmployer = (index) => {
    const updatedEmployers = [...employers];
    updatedEmployers.splice(index, 1); // Remove the employer at the specified index
    setEmployers(updatedEmployers); // Update the state with the modified employers array

    // Update the database to reflect the changes
    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, year, pan);

    try {
      updateDoc(docRef, { Employers: updatedEmployers });
      console.log("Employer deleted successfully!");
    } catch (error) {
      console.error("Error deleting employer:", error);
    }
  };


  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        // console.log("sign out successful");
      })
      .catch((error) => console.log(error));
  };
  const whatsappMeFxn = () => {
    window.open("https://wa.me/7276016790");
  };
  const whatsappMeFxnOffice = () => {
    window.open("https://wa.me/7276016790");
  };

  const handleChange = (e) => {
    const maxAllowedSize = 0.5 * 1024 * 1024;
    if (e.target.files[0].size < maxAllowedSize) {
      setPhoto(e.target.files[0]);
      setLoading(false);
    } else {
      toast(t("Image size should be less than 500kb"), { icon: "📷" });
      setLoading(true);
      return;
    }
  };

  const updateImg = async (e) => {
    const authUser = auth.currentUser;
    if (authUser) {
      const match = authUser.email.match(/\+(.*?)@/);
      const userPan = match ? match[1].toUpperCase() : "";

      if (userPan) {
        const fileName = `${userPan}_profile.png`;
        const fileRef = ref(storage, "profileImg/" + fileName);

        setLoading(true);
        // eslint-disable-next-line
        const snapshot = await uploadBytes(fileRef, photo);
        const photoURL = await getDownloadURL(fileRef);

        updateProfile(authUser, { photoURL });

        setLoading(false);
        toast.success(
          t("Profile Image Updated! It may take some time to display on profile...")
        );
      }
    }
    window.location.reload(false);
  };

  const handleClick = async (year) => {
    const authUser = auth.currentUser;
    console.log("year is..", year);

    if (authUser) {
      const match = authUser.email.match(/\+(.*?)@/);
      const userPan = match ? match[1].toUpperCase() : "";

      if (userPan) {
        const fileName = `${userPan}_ITRAcknowledgement`;
        const storageRef = ref(storage, `ITRAcknowledgement/AY-${year}/${fileName}`);

        try {
          const fileUrl = await getDownloadURL(storageRef);
          window.open(fileUrl, "_blank");
        } catch (error) {
          console.error("Error getting file URL: ", error);
          toast.error("No Docoment Found for this year", error);
        }
      } else {
        toast.error("Unable to retrieve user PAN");
      }
    } else {
      toast.error("User not logged in");
    }
  };

  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);

    return date;
  }

  const submitQuery = () => {
    if (!query) {
      toast.error("Query not entered");
    }

    raiseQuery(
      props.pan,
      props.name,
      mobile,
      new Date(),
      "not resolved",
      roleSet,
      queryValue,
      "",
      addWeeks(new Date(), 1)
    );
  };

  return (
    <div className="container-md mt-5">
      {/* profile image */}
      <center>
        <div className="box">
          {/* <!-- Button trigger modal --> */}
          <button
            type="button"
            className="btn btn-primary m-2"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            {t("Update Profile Image")}
          </button>

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="exampleModal"
            style={{ marginTop: "50px" }}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("Update Profile Image")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="text-center mt-2 ">
                    <img
                      src={photoUrl}
                      className="rounded-circle"
                      style={{ objectFit: "cover" }}
                      alt="user avatar"
                      width="150"
                      height="150"
                    // onChange={handleSelect}
                    ></img>
                    <input
                      className="row"
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={loading || !photo}
                    onClick={updateImg}
                  >
                    {t("Save changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-signup btn-primary m-">
            <Link to="/auth/change_pwd">{t("Reset Password")}</Link>
          </button>
          <button
            className="btn btn-login btn-danger m-2"
            onClick={userSignOut}
          >
            {t("Log Out")}
          </button>
        </div>
      </center>

      {/* accordion starts */}
      <div className="accordion" id="accordionExample">

        {/* account details starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray position-relative">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill "
              // onClick={() => setIsEditVisible()}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {t("Account Details")}
            </button>
          </h2>
          {/* {isEditVisible ? (
            <div
              className="accordion-body  d-flex justify-content-end"
              style={{
                position: "absolute",
                top: "-5px",
                right: "50px",
                zIndex: "100",
              }}
            >
              {!edit ? (
                <EditButton onTap={editAccountInfo} />
              ) : (
                <SubmitButton />
              )}
            </div>
          ) : (
            <></>
          )} */}
          <div
            id="collapseOne"
            // className="accordion-collapse collapse show pb-3"
            data-bs-parent="#accordionExample"
          >
            <form onSubmit={onSubmit}>
              {accountInfo.map((item, index) => {
                const isPolice = roleSet == "Policeman";

                {/* if (!isPolice && index == 3) {
                  return null;
                } */}

                return (
                  <div className="accordion-body py-2" key={index}>
                    <label className="d-flex text-light justify-content-between">
                      {item.label}
                    </label>
                    {index !== 8 ? (
                      <input
                        name={item.name}
                        type="text"
                        className="form-control rounded-pill border-0"
                        placeholder={t("Enter ") + item.label}
                        value={item.value}
                        readOnly={edit ? null : "readOnly"}
                        onChange={(e) => item.setValue(e.target.value)}
                      />
                    ) : (
                      <textarea
                        name={item.name}
                        type="text"
                        className="form-control rounded-pill border-0"
                        placeholder={t("Enter ") + item.label}
                        value={item.value}
                        style={{ resize: "none" }}
                        readOnly={edit ? null : "readOnly"}
                        onChange={(e) => item.setValue(e.target.value)}
                      ></textarea>
                    )}
                  </div>
                );
              })}
              <div className="accordion-body pb-4 d-flex justify-content-end pe-5">
                {!edit ? (
                  <EditButton onTap={editAccountInfo} />
                ) : (
                  <SubmitButton />
                )}
              </div>
            </form>
          </div>

        </div>
        {/* account details ends */}



        {/* Employer starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEmployer"
              aria-expanded="false"
              aria-controls="collapseEmployer"
            >
              {t("Employer Details")}
            </button>
          </h2>
          <div id="collapseEmployer" className="pb-2" data-bs-parent="#accordionExample">
            <div className="accordion-body py-2">
              <div style={{ background: "white", borderRadius: "25px", padding: "1rem" }}>
                <h4>{t("Primary Employer Details")}</h4>

                <form onSubmit={onPrimarySubmit}>
                  {primaryInfo.map((item, index) => {

                    return (
                      <div className="accordion-body py-2" key={index}>
                        <label className="d-flex text-dark justify-content-between">
                          {item.label}
                        </label>
                        {index !== 8 ? (
                          <input
                            name={item.name}
                            type="text"
                            className="form-control rounded-pill border border-secondary mt-2"
                            placeholder={t("Enter ") + item.label}
                            value={item.value}
                            readOnly={editPrimary ? null : "readOnly"}
                            onChange={(e) => item.setValue(e.target.value)}
                          />
                        ) : (
                          <textarea
                            name={item.name}
                            type="text"
                            className="form-control rounded-pill border-0"
                            placeholder={t("Enter ") + item.label}
                            value={item.value}
                            style={{ resize: "none" }}
                            readOnly={editPrimary ? null : "readOnly"}
                            onChange={(e) => item.setValue(e.target.value)}
                          ></textarea>
                        )}
                      </div>
                    );
                  })}
                  <div className="accordion-body pb-4 d-flex justify-content-end pe-5">
                    {!editPrimary ? (
                      <EditButton onTap={editPrimaryInfo} />
                    ) : (
                      <SubmitButton />
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="accordion-body py-2">
              <div style={{ background: "white", borderRadius: "25px", padding: "1rem" }}>
                <h4>{t("Other Employer Details")}</h4>
                <form onSubmit={onEmployerSubmit}>
                  <div className="employer-container mt-3">
                    {employers.slice(1).map((employer, index) => ( // Starts from index 1
                      <div key={index} className="card p-3">
                        <div className="mb-3">
                          <label htmlFor="employerName" className="form-label">
                            {t("Employer/Office/Company Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border border-secondary"
                            placeholder="Enter Employer/Office/Company Name"
                            value={employer.employerName}
                            readOnly={!editOtherEmp}
                            onChange={(e) => onEmployerChange(index + 1, "employerName", e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="tanNumber" className="form-label">
                            {t("TAN (Deductor)")}
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border border-secondary"
                            pattern="^[A-Za-z]{4}\d{5}[A-Za-z]$"
                            placeholder="Enter TAN (Deductor)"
                            value={employer.tan}
                            readOnly={!editOtherEmp}
                            onChange={(e) => onEmployerChange(index + 1, "tan", e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="panNumber" className="form-label">
                            {t("PAN (Deductor)")}
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border border-secondary"

                            placeholder="Enter PAN (Deductor)"
                            pattern="[A-Za-z\]{10}"
                            value={employer.empPAN}
                            readOnly={!editOtherEmp}
                            onChange={(e) => onEmployerChange(index + 1, "empPAN", e.target.value)}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="mb-1 me-2">
                            {!editOtherEmp ? (
                              <EditButton onTap={editOtherEmpInfo} />
                            ) : (
                              <SubmitButton />
                            )}
                          </div>
                          <div className="mb-1">
                            <DeleteButton onTap={() => deleteEmployer(index + 1) } /> {/* Adjust index for deletion */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>

                <div>
                  {showAddButton && ( // conditionally render add button
                    <button
                      className="btn btn-primary rounded-pill border-0 my-3"
                      style={{ backgroundColor: "#007BFF" }}
                      onClick={() => {
                        setShowInputFields(true);
                        setShowAddButton(false); // hide add button when clicked
                      }}
                    >
                      {t("Add Employer")}
                    </button>
                  )}
                  {showInputFields && ( // conditionally render input fields
                    <div className="card p-3 border-0">
                      <div className="mb-3">
                        <label htmlFor="employerName" className="form-label">
                          {t("Employer/Office/Company Name")}
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill border border-secondary"
                          placeholder="Enter Employer/Office/Company Name"
                          value={employerName}
                          onChange={(e) => setEmployerName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="tanNumber" className="form-label">
                          {t("TAN (Deductor)")}
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill border border-secondary"
                          pattern="^[A-Za-z]{4}\d{5}[A-Za-z]$"
                          placeholder="Enter TAN (Deductor)"
                          value={tan}
                          onChange={(e) => setTan(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="panNumber" className="form-label">
                          {t("PAN (Deductor)")}
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill border border-secondary"
                          pattern="[A-Za-z\]{10}"
                          placeholder="Enter PAN (Deductor)"
                          value={empPAN}
                          onChange={(e) => setEmpPAN(e.target.value)}
                        />
                      </div>
                      <button className="btn btn-success rounded-pill border-0 my-3" onClick={addEmployer}>
                        {t("Submit")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* Employer ends */}

        {/* Previous ITR's starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {t("Previous ITR's")}
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={() => handleClick("2023-24")}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2023-24"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={() => handleClick("2024-25")}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2024-25"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
          </div>
        </div>
        {/* Previous ITR's ends */}

        {/* Form 16 section starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              {t("Form 16")}
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2020-21"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value="2021-22"
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
            <div className="accordion-body">
              <a
                href={link !== "" ? link : undefined}
                disabled={link === ""}
                onClick={handleClick}
                target="_blank"
                rel="noreferrer"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={year}
                  style={{ cursor: "pointer" }}
                  readOnly
                />
              </a>
            </div>
          </div>
        </div>
        {/* Form 16 ends here */}

        {/* Contact starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              {t("Contact")}
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse p-2"
            data-bs-parent="#accordionExample"
          >
            {/* contact detail 1 starts */}
            <div className="card contact-card m-4">
              <div className="row no-gutters px-4">
                <div className="col-md-8 d-flex justify-content-center p-3">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      src="https://tinyurl.com/4ypa9c6z"
                      className="rounded-circle img-thumbnail img-fluid object-fit-cover"
                      alt="Profile avatar"
                      width="100px"
                      height="100px"
                    // onChange={handleSelect}
                    ></img>
                  </div>

                  <div className="col-9 d-flex justify-content-center align-items-center">
                    <div className="card-body">
                      <h5 className="card-title">
                        {t("Atharv Sawant (Founder & Chairman)")}
                      </h5>
                      <p className="card-text">+91 7276016790</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="m-2">
                    <a href="tel:7276016790">
                      <button type="button" className="btn btn-primary">
                        {t("Call")}
                      </button>
                    </a>
                  </div>
                  <div className="m-2">
                    <button
                      onClick={whatsappMeFxn}
                      type="button"
                      className="btn btn-secondary whatsapp-btn"
                    >
                      {t("Whatsapp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* contact detail 1 ends */}

            {/* contact detail 2 starts */}
            <div className="card contact-card m-4">
              <div className="row no-gutters px-4">
                <div className="col-md-8 d-flex justify-content-center p-3">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      src="assets/images/icons/favicon.png"
                      className="rounded-circle img-thumbnail img-fluid object-fit-cover"
                      alt="Profile avatar"
                      width="100px"
                      height="100px"
                    ></img>
                  </div>

                  <div className="col-9 d-flex justify-content-center align-items-center">
                    <div className="card-body">
                      <h5 className="card-title">{t("Tax Sarthi Office")}</h5>
                      <p className="card-text">+91 7276016790</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="m-2">
                    <a href="tel:7276016790">
                      <button type="button" className="btn btn-primary">
                        {t("Call")}
                      </button>
                    </a>
                  </div>
                  <div className="m-2">
                    <button
                      onClick={whatsappMeFxnOffice}
                      type="button"
                      className="btn btn-secondary whatsapp-btn"
                    >
                      {t("Whatsapp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* contact detail 2 ends */}
          </div>
        </div>
        {/* Contact ends */}

        {/* Raise Query starts */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              {t("Raise Query")}
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse p-2 m-2"
            data-bs-parent="#accordionExample"
          >
            <label className="d-flex mb-2 text-light justify-content-between">
              {t("Enter Query")}
            </label>
            <input
              type="text-area"
              className="form-control rounded-pill border-0"
              value={queryValue}
              onChange={(e) => {
                setQueryValue(e.target.value);
              }}
              id="query input field"
            ></input>
            <button
              className="btn btn-secondary rounded-pill border-0 my-3"
              onClick={submitQuery}
            >
              {t("Submit")}
            </button>
          </div>
        </div>
        {/* Raise Query ends */}

        {/* Previous Queries section */}
        <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
          <h2 className="accordion-header">
            <button
              className="accordion-button shadow-none text-light bg-dark-gray rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              {t("Previous Queries")}
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse p-2"
            data-bs-parent="#accordionExample"
          >
            {/* Render previous queries here */}
            {previousQueries.map((query, index) => (
              <div key={index} className="query-item">
                <div style={{ color: "white", marginLeft: "10px" }}>Query : {query.query}
                  <span style={{ color: "white", fontWeight: "800", fontSize: "20px", marginLeft: "300px" }}>Status : {query.status}</span>
                </div>

                {/* Add any other details you want to display */}
              </div>
            ))}
          </div>
        </div>

      </div>
      {/* accordion ends */}
    </div>
  );
};



export default function ProfileErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <Profile
        name={props.name}
        pan={props.pan}
        phone={props.phone}
        userType={props.userType}
      />
    </ErrorBoundary>
  );
}
