import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  // limit,
  getDocs,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import toast from "react-hot-toast";

export const raiseQuery = async (
  clientPAN,
  clientName,
  phone,
  raisedOn,
  status,
  userType,
  queryValue,
  resolution,
  deleteOn
) => {
  // Selecting the queries
  const docRef = doc(db, "queries", clientPAN);
  // updating the value
  try {
    setDoc(docRef, {
      clientName: clientName,
      pan: clientPAN,
      phone: phone,
      raisedOn: raisedOn,
      status: status,
      userType: userType,
      query: queryValue,
      resolution: resolution,
      deleteOn: deleteOn,
    });
    toast.success("Query Raised. Our team will contact you soon.");
  } catch (error) {
    toast.error(
      "Sorry error in raising query. Please contact on the office contact number. Please report the error occurred."
    );
  }
};
