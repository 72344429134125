import React from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
const ListItem = ({
  value1,
  value2 = null,
  value3 = null,
  bgcolor = "dark-gray",
  color1 = "light",
  color2 = "light",
  color3 = "light",
  id = null, // eslint-disable-line no-unused-vars
  link = "/",
  navi = false,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const [year, setYear] = useState(process.env.REACT_APP_CURRENT_YEAR);

  const { t } = useTranslation();
  const userId = auth.currentUser.uid;

  const match = auth.currentUser.email.match(/\+(.*?)@/);
  const pan = match[1].toUpperCase();



  const handleClick = async () => {
    if (navi) {
      navigate(link);
    }
    if (!disabled) {
      const docRef = doc(db, year.toString(), pan);
      await updateDoc(docRef, {
        punch: true,
      })
        .then(() => {
          toast.success("Data sent successfully");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    }
  };
  return (
    <div
      className={`bg-${bgcolor} px-4 py-2 my-2 rounded d-flex justify-content-center align-items-center`}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <span className={`text-${color1} px-1`}>{t("You are eligible for deduction of")} {value1}</span>
      {value2 && <span className={`text-${color2} px-1`}>{t("upto Rs.")}{value2}</span>}
      {value3 && (
        <span className={`text-${color3} text-center px-1`}>{value3}</span>
      )}
    </div>
  );
};

export default ListItem;
