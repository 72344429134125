// Learning.js
import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import Modal from "./Model"; // Import the Modal component
import "./course.css";
import { useTranslation } from "react-i18next";

const API_KEY = "AIzaSyBTtWqwbO1ImsUzzJyVI0Ot1_a5ngA7ONQ";
const PLAYLIST_URL = "https://www.youtube.com/playlist?list=PLtztnlFXOSfydSp27GrEjxVTEMTr8eP-j";

function Learning() {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    const videoIds = getVideoIdsFromUrl(PLAYLIST_URL);
    if (videoIds) {
      fetchVideoList(videoIds, API_KEY).then((videoList) => setVideos(videoList));
    }
  }, []);

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
    setShowModal(true); // Show the modal when a video is selected
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedVideo(null); // Reset the selected video
  };

  const videoOptions = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="learning-container border">
      <h1 className="learning-heading">{t("Course Playlist")}</h1>
      <Modal show={showModal} onClose={handleCloseModal}>
        {selectedVideo && (
          <YouTube videoId={selectedVideo} opts={videoOptions} />
        )}
      </Modal>
      <div className="video-list">
        {videos.map((video) => (
          <div data-aos="fade-up"
            className="video-item"
            key={video.id}
            onClick={() => handleVideoClick(video.id)}
          >
            <div className="thumbnail-container">
              <img src={video.thumbnail} alt={video.title} />
            </div>
            <h3>{video.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

const getVideoIdsFromUrl = (url) => {
  const urlParams = new URL(url).searchParams;
  return urlParams.get("list");
};

const fetchVideoList = (videoIds, apiKey) => {
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${videoIds}&key=${apiKey}`;
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      const videoList = data.items.map((item) => ({
        id: item.snippet.resourceId.videoId,
        title: item.snippet.title,
        description: item.snippet.description,
        thumbnail: item.snippet.thumbnails.maxres?.url || item.snippet.thumbnails.default.url,
      }));
      return videoList;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export default Learning;